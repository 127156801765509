import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HelpContainer from "../../components/home/helpSection/HelpContainer";
import KeepAlive from 'react-activation';
// import DiceContainer from "../../components/games/dice/DiceContainer";

import { StyledContainer, StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledAboutDice } from "../../components/games/dice/styledDice";
import { StyledBlockTitle, StyledParagraph } from "../../components/styles/styledDocumetnElemets";
import AlertMessage from "../../components/elements/alert/Alert";
import DiceBetsHistoryWrapper from "../../components/games/dice/DiceBetsHistoryWrapper";
import {Snowfall} from "react-snowfall";
import {useLayout} from "../../utils/useLayout";
const DiceContainer = React.lazy(() => import('../../components/games/dice/DiceContainer'));

const HomePage = () => {
  const { t } = useTranslation("games");
  const { isMobile } = useLayout();
  const [pageHeight, setPageHeight] = useState(0);
  const [showSnow, setShowSnow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setPageHeight(document.documentElement.scrollHeight);
    };

    const snowTimer = setTimeout(() => {
      handleResize();
      setTimeout(() => setShowSnow(true), 0);
    }, 1000);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.dice.title")}</title>
        <meta
          name="description"
          content={t("meta.dice.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/"}
          hrefLang="ru-RU"
        />
      </Helmet>
      {showSnow && <Snowfall radius={[0.5, 2]}  snowflakeCount={isMobile ? 31 : 150} speed={[0.5, 1.5]} style={{height: `${pageHeight}px`}}/>}
        <HelpContainer />
        <KeepAlive>
        <DiceContainer />
      </KeepAlive>
      <DiceBetsHistoryWrapper />
      <StyledAboutDice>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleBestCasino")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textBestCasino")}
        </StyledParagraph>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleDescriptionBones")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textDescriptionBones")}
        </StyledParagraph>
      </StyledAboutDice>
    </StyledContainer>
  );
};

export default HomePage;
