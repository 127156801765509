import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { generatePath } from "../../../utils/getLanguage";
import { TimestampToAgoFormat, TimestampToCustomFormat, TimestampToDateTime } from "../../../utils/timestampToDate";
import { StyledHistoryBet, StyledHistoryDate, StyledHistoryLink } from "../../styles/styledHistory";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
import { StyledDesktopSpan, StyledDiceHistoryProgress } from "./styledDice";

const DiceBetsListItem = React.memo((props) => {
  const { value, user, handleProfileActionsVisible } = props;

  const { t } = useTranslation("games");

  return (
    <tr>
      <td data-title="ID">
        <StyledHistoryLink as={NavLink} to={`/bet/${value.id}`}>
          {new Intl.NumberFormat("en-US").format(value.id)}
        </StyledHistoryLink>
      </td>
      <td data-title="Время / Игрок">
        <StyledHistoryDate>
          {TimestampToCustomFormat(value.createdAt)}
        </StyledHistoryDate>
        <StyledHistoryLink as={NavLink} to={generatePath(`/account/${value.user.nickname}`)}>
          <div>
            {value.user.nickname}
          </div>
        </StyledHistoryLink>
      </td>
      <td data-title="Валюта" className="dice-history-table__currency">
        <StyledPaymentMethodsIcons className={`payment-method-${value?.paymentMethod?.currency?.asset}`}/>
      </td>
      <td data-title="Прогноз / Выпавший номер" className="dice-history-table__bet">
        <p>{value.gameObject.sign ? t("moreSmall") : t("lessSmall")} {t("than")} {value.suggestedNumbers}</p>
        <StyledDiceHistoryProgress
          className="bet-progress"
          sign={value.gameObject.sign}
          suggestedNumbers={value.suggestedNumbers}
          drawnNumber={value.drawnNumber}
          losing={parseFloat(value.lose) === 0}
        >
          <div className="bet-progress__line"/>
          <div className="bet-progress__count">
            <span className="bet-progress__num">{value.drawnNumber}</span>
          </div>
        </StyledDiceHistoryProgress>
      </td>
      <td data-title="Ставка">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
          <span>
            {value.gameObject.chanceToWin}%
          </span>
          {value.bet}
          <StyledDesktopSpan>{" " + value?.paymentMethod?.currency?.asset}</StyledDesktopSpan>
        </StyledHistoryBet>
      </td>
      <td data-title="Выигрыш">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
          <span>
            {parseFloat(value.lose) === 0 ? new Intl.NumberFormat("en-US", { minimumFractionDigits: 5 }).format(parseFloat(value.gameObject.coefficient).toFixed(5)).slice(0, -1) : 0}x
          </span>
          {parseFloat(value.lose) === 0 ? (parseFloat(value.bet) * value.gameObject.coefficient).toFixed(8) : parseFloat("0").toFixed(8)}

          <StyledDesktopSpan>{" " + value?.paymentMethod?.currency?.asset}</StyledDesktopSpan>
        </StyledHistoryBet>
      </td>
    </tr>
  );
});

export default DiceBetsListItem;
