import styled from "styled-components";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { StyledSiteOptionsWrapper } from "../../elements/siteOptions/styledSiteOptions";

export const StyledBallsWrapper = styled.section`
  ${({isMobile}) => !isMobile ? '' : ''}
  ${({isMobile}) => !isMobile ? 'padding: 0px;' : 'padding: 20px 0 20px;'}
  ${({isMobile}) => !isMobile ? 'padding-top: 30px;' : ''}
  ${({isMobile}) => !isMobile ? 'height: 480px;' : 'height: 290px;'}

  ${({isMobile}) => !isMobile ? '' : 'margin: 0 auto;'}
  ${({isMobile}) => !isMobile ? '' : 'box-sizing: border-box;'}
  ${({isMobile}) => !isMobile ? '' : 'width: 100%'}
  ${({isMobile}) => !isMobile ? '' : 'max-width: 1100px;'}
  background: url("${({ theme, isMobile }) => isMobile ? theme.bgBallsMobile : theme.bgBalls}") no-repeat 50% ${({isMobile}) => !isMobile ? '0' : '-145px'};
  @media screen and (max-width: 1100px) {
    .jackpot {
      margin-left: 0 !important;
    }
  }
  &:hover {
    ${StyledSiteOptionsWrapper} {
      .jackpot {
        opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

        &:hover {
          opacity: 1;
          background-color: ${({ theme }) => theme.optionBackgroundHover};
          img {
            opacity: 1 !important;
            transition: opacity 0.3s;
          }
        }
        img {
          transition: opacity 0.3s;
        }
      }

      .panel {
        &__item {
          opacity: ${({ theme }) => theme.optionOpacityHoverContainer};

          &:hover {
            opacity: 1;
            background-color: ${({ theme }) => theme.optionBackgroundHover};
            .icon {
              opacity: 1;
              transition: opacity 0.3s ease-out;
            }
          }
          .icon {
            transition: opacity 0.3s ease-out;
          }
        }
      }
    }
  }
`;

export const StyledMagnet = styled.div`
  background-repeat: no-repeat;
    // ${({isMobile}) => isMobile ? 'width: 70px;' : ''}
  ${({isMobile}) => isMobile ? 'height: 20px;' : 'height: 30px;'}
  background-image: url("${({ theme }) => theme.ballsMagnet}");
  background-position-x: 0px;
  background-position-y: ${({isRunMagnet}) => isRunMagnet ? "100%" : "0"};
  ${({isMobile}) => isMobile ? 'background-size: 70px 200%;' : ''}
  z-index: 2;
  @media screen and (max-width: 1100px) {
      // background-position-x: ${({position}) => position === 10 ? '100%' : position ? `${90 * (position / 9)}%` : '0'};
  }
  ${({isMobile}) => isMobile ? 'margin-left: -10px;' : ''}
  ${({isMobile}) => isMobile ? 'margin-right: -10px;' : ''}
`;

export const StyledBallsSelectWrapper = styled.div`
  padding-top: ${({isMobile}) => !isMobile ? '30px' : '0px'};
`;



export const StyledBallsRow = styled.div`
  display: flex;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (max-width: 1100px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 150px;
    // flex-direction: row;
    // justify-content: space-between;
  }
`;

const bgPositionMobile = (position) => {
  switch (position) {
    case 0:
      return `
        background-position: 0 140px;
      `
    case 1:
      return `
        background-position: -57.5px 140px;
      `
    case 2:
      return `
        background-position: -115px 140px;
      `
    case 3:
      return `
        background-position: -172.5px 140px;
      `
    case 4:
      return `
        background-position: -230px 140px;
      `
    case 5:
      return `
        background-position: -287.5px 140px;
      `
    case 6:
      return `
        background-position: -345px 140px;
      `
    case 7:
      return `
        background-position: -402.5px 140px;
      `
    case 8:
      return `
        background-position: -460px 140px;
      `
    case 9:
      return `
        background-position: -517.5px 140px;
      `
    case 10:
      return `
        background-position: -575px 140px;
      `
  }
}

const bgPosition = (position) => {
  switch (position) {
    case 0:
      return `
        background-position: 0 140px;
      `
    case 1:
      return `
        background-position: -92px 140px;
      `
    case 2:
      return `
        background-position: -184px 140px;
      `
    case 3:
      return `
        background-position: -276px 140px;
      `
    case 4:
      return `
        background-position: -368px 140px;
      `
    case 5:
      return `
        background-position: -460px 140px;
      `
    case 6:
      return `
        background-position: -552px 140px;
      `
    case 7:
      return `
        background-position: -644px 140px;
      `
    case 8:
      return `
        background-position: -736px 140px;
      `
    case 9:
      return `
        background-position: -828px 140px;
      `
    case 10:
      return `
        background-position: -920px 140px;
      `
  }
}

export const StyledBallItem = styled.div`
  width: 80px;
  ${({isMobile, position,rowWidth}) => !isMobile ? `
    position: absolute;
    left: ${90 * position}px;
    float: left;
  ` : `
    position: absolute;
    left: ${(((rowWidth - 50) / 10) * position)}px;
    float: left;
  `
  }
  cursor: pointer;
  padding: 0 5px;
  ${({isMobile}) => isMobile ? 'margin-right: 0px;' : 'margin-right: 0px;'}
  ${({isMobile}) => isMobile ? 'height: 150px;' : 'height: 240px;'}
  background: url("${({ theme, isMobile }) => isMobile ? theme.ballsMobileItem : theme.ballsItem}") bottom no-repeat;
  z-index: 9!important;
  opacity: 0.80;
  ${({position, isMobile}) => isMobile ? bgPositionMobile(position) : bgPosition(position)}
  ${({active, theme}) =>  active && `
    background-image: url(${theme.ballsItemActive});
    opacity: 1;
  `};
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: 1100px) {
    width: 50px;
    min-width: 50px;
    background-size: 625px 50px;
  }
    /*
  @media screen and (max-width: 1100px) {
    margin-right: 0;
  }
  @media screen and (max-width: 980px) {
    left: ${({position}) => `${88 * (position / 9.7)}%`};
  }
  @media screen and (max-width: 768px) {
    left: ${({position}) => `${84 * (position / 9.7)}%`};
  }
  @media screen and (max-width: 540px) {
    left: ${({position}) => `${79 * (position / 9.7)}%`};
  }
  @media screen and (max-width: 375px) {
    left: ${({position}) => `${72 * (position / 9.7)}%`};
  }
  */
`;

/*
#gameContainer .game-balls .ballsRow .ballItem span.hide {
    width: 60px;
    height: 15px;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0 20px 10px rgba(20, 20, 20, .5);
    opacity: 0.35;
}
*/

export const StyledBallShadowItem = styled.span`
  ${({isMobile,hide}) => isMobile ? `
  position: absolute;
  bottom: ${hide ? 0: 0}px;
  left: ${hide ? 0 : 5}px;
  width: ${hide ? 60: 40}px;
  height: ${hide ? 15: 15}px;
  z-index: 0!important;
  background: rgba(20, 20, 20, .5);
  box-shadow: ${hide ? '0px 0 20px 10px rgb(20 20 20 / 50%)': '0px 0 15px 10px rgb(20 20 20 / 50%)'};
  border-radius: 1000px;
  opacity: ${hide ? 0.35 : 1.0};
  transition: all 0.4s ease-out;
  ` : `
  position: absolute;
  bottom: ${hide ? 10: 15}px;
  left: ${hide ? 5: 15}px;
  width: ${hide ? 70: 50}px;
  height: ${hide ? 30: 20}px;
  z-index: 0!important;
  background: rgba(20, 20, 20, .5);
  box-shadow: ${hide ? '0px 0 20px 10px rgb(20 20 20 / 50%)': '0px 0 15px 10px rgb(20 20 20 / 50%)'};
  border-radius: 1000px;
  opacity: ${hide ? 0.35 : 1.0};
  transition: all 0.4s ease-out;
  `}
`;

// export const StyledBallsBox = styled.div`
//   width: 465px;
//   height: 230px;
//   font-size: 14px;
//   font-weight: 500;
//   text-align: center;
//   text-transform: uppercase;
//   display: flex;
//   justify-content: space-between;
//   border-radius: 10px;
//   position: relative;
//   ${({theme}) => theme.ballsBox}
//   &:before {
//     content: "";
//     width: 5px;
//     height: 100%;
//     margin-left: -2.5px;
//     display: block;
//     pointer-events: none;
//     background: ${({theme}) => theme.ballsBoxLine};
//     position: absolute;
//     top: 0;
//     left: 50%;
//   }
//   &:after {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 100%;
//     top: 100%;
//     left: 0;
//     box-shadow: 0 0 25px 14px rgb(0 0 0 / 20%);
//   }
//   .balls-number {
//     width: 50%;
//     height: 220px;
//     padding-right: 2px;
//     padding-bottom: 10px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     input {
//       width: 100%;
//       height: 115px;
//       color: ${({theme}) => theme.subTitleColor};
//       font-size: 130px;
//       font-weight: 600;
//       text-align: center;
//       line-height: 115px;
//       background: none;
//       border: none;
//       box-shadow: none;
//     }
//     &__result input {
//       color: #2eac5b;
//     }
//   }
// `;

// export const StyledBallsDirection = styled.div`
//   order: ${({direction}) => !direction && "-1"};
//   button {
//     color: #797778;
//     font-size: 32px;
//     text-transform: uppercase;
//     border: none;
//     outline: none;
//     position: relative;
//     cursor: pointer;
//     transition: all .3s ease;
//     &:hover {
//       color: ${({theme}) => theme.subTitleColor};
//       opacity: 0.75;
//     }
//   }
//   button.active {
//     color: ${({theme}) => theme.subTitleColor};
//     &:after {
//       content: "";
//       display: block;
//       width: 100%;
//       height: 1px;
//       background: #919294;
//       position: absolute;
//       top: 100%;
//       left: 0;
//     }
//   }
// `;

export const StyledBallsBetWrapper = styled.div`
  background-color: ${({theme}) => theme.elementBg};
`;

export const StyledBallsBetContent = styled(StyledContentWrapper)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 1100px) {
    grid-template-columns: unset;
    padding: 20px 20px 30px;
  }
`;

export const StyledBallsBetOptions = styled.div`
  padding: 20px 0 20px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;

  & .wrapper-group {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-gap: 10px 15px;
  }

  .input-group {
    margin-bottom: 0;
    label, input {
      text-align: center;
    }
  }
  @media screen and (max-width: 1100px) {
    padding: 0;
    flex-direction: column-reverse;
    button {
      margin: 0;
    }
  }
`;

export const StyledBallsHistoryWrapper = styled.div`
  padding: 40px 0 30px;
  background-image: url("${({ theme }) => theme.bgBallsHistory}");
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  .balls-history-table {
    padding: 0 0 10px;
    &__head, &__row {
      grid-template-columns: 120px 140px 90px minmax(240px, 1fr) 150px 150px;
    }
    &_loading,
    &_empty {
      padding: 12px 15px;
      grid-template-columns: 1fr;
      text-align: center;
      text-transform: uppercase;
    }
    &__currency,
    &__number {
      text-align: center;
      justify-content: center;
    }
  }
  .default-tabs-top {
    gap: 10px;
  }
  @media screen and (max-width: 1100px) {
    padding: 0;
  }
`;

export const StyledAboutBalls = styled.div`
  max-width: 900px;
  margin: 0 auto 50px;
  @media screen and (max-width: 1080px) {
    padding: 0 5%;
  }
`;

export const StyledBallsContentWrapper = styled.div`
  max-width: ${({ width }) => width ? width + "px" : "1000px"};
  display: ${({ isFlex }) => isFlex ? "flex" : "block"};
  flex-direction: ${({ column }) => column ? "column" : "row"};
  align-items: ${({ position }) => position ? position : "unset"};
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1100px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 350px) {
    padding: 0 13px;
  }
    /*

  @media screen and (max-width: 1080px) {
    ${({ roulette }) => !roulette && "padding: 0 5%"}
  }
  @media screen and (max-width: 768px) {
    .account-wrapper__top {
      flex-direction: column;
      align-items: center;

      & > div {
        width: 100%;
      }
    }
    .account-wrapper__other {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  */
`;
