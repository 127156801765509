import {
    StyledClientBalancesBtnBlock,
    StyledClientBalancesContainer,
    StyledClientBalancesList, StyledClientBalancesListItem,
    StyledClientBalancesTitle, StyledClientEstimatedBlock
} from "./styledClientDetails";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";
import Big from "big.js";
import {StyledPaymentMethodsIcons} from "../../../styles/StyledPaymentMethodsIcons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {StyledButton} from "../../../styles/styledButton";
import {useTranslation} from "react-i18next";
import DiceStates from "../../../games/dice/DiceStates";
import BallsStates from "../../../games/balls/BallsStates";
import RouletteStates from "../../../games/roulette/RouletteStates";
import {defaultCurrentStatistics, games, responseStatus} from "../../../../utils/consts";
import {AppContext} from "../../../../App";
import CurrentStatisticStates from "../../../games/CurrentStatisticStates";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../../elements/notification/ClosableNotification";
import Spinner from "../../../elements/spinner/Spinner";
import {useLayout} from "../../../../utils/useLayout";

function ClientDetailsMainInfo() {
    const {t} = useTranslation("games");
    const {t: tO} = useTranslation("siteOptions");

    const [hoveredId, setHoveredId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {
        paymentMethods,
        setPaymentDialogVisible,
        setPayoutDialogVisible,
        setActiveCurrency,
        setBalance,
        setPaymentMethods
    } = useBetween(BalanceStates);
    const {setCurrency} = useBetween(CurrentStatisticStates);
    const {isMobile} = useLayout();
    const {authenticated} = useContext(AppContext);

    let page = DiceStates;

    if (localStorage.getItem("gamePage")?.includes(games.balls)) {
        page = BallsStates;
    } else if (localStorage.getItem("gamePage")?.includes(games.roulette)) {
        page = RouletteStates;
    }

    const {setSubmitData} = useBetween(page);

    useEffect(() => {
        if ((paymentMethods.length > 0 && paymentMethods[0]?.balances) || isMobile) return;
        setIsLoading(true);
        fetchPaymentMethod();
    }, [paymentMethods.length])

    const fetchPaymentMethod = () => {
        axios.get("/api/payment-methods", userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setPaymentMethods(response.data["hydra:member"]);

                setSubmitData((prevState) => ({
                    ...prevState,
                    currencyAsset: response.data["hydra:member"][0]?.currency.asset
                }));
                setIsLoading(false);
            }
        }).catch(error => {
            if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
                closableNotification(error.response.data.error, "error");
                setIsLoading(false);
            }
        });
    };

    const selectPaymentMethod = useCallback((value) => {
        if (authenticated) {
            localStorage.setItem("paymentMethod", JSON.stringify(value));
            localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
        }
        setCurrency(value.currency.asset);
        setActiveCurrency(value.currency.asset);

        setBalance(value.balances ? value.balances.amount : 0);

        setSubmitData((prevState) => ({
            ...prevState,
            paymentMethod: value
        }));
    }, [authenticated, setActiveCurrency, setBalance, setCurrency, setSubmitData]);

    const filteredPaymentMethods = paymentMethods.filter((paymentMethod) =>
    new Big(paymentMethod?.balances?.amount ?? 0).gt(0)
    );

    if ((filteredPaymentMethods.length === 0 && !isLoading && !isMobile) || (isMobile && filteredPaymentMethods.length === 0 && paymentMethods.length > 0)) {
        return null;
    }

    const estimatedValue = filteredPaymentMethods.reduce((total, method) => {
        const amount = new Big(method?.balances?.amount ?? 0);
        const rate = method.currency.asset === "LUCKY" ? new Big(0) : new Big(method?.currency?.rate ?? 0);
        return total.plus(amount.times(rate));
    }, new Big(0));

    const handleDepositButtonClick = (paymentMethod) => {
        selectPaymentMethod(paymentMethod);
        setTimeout(() => setPaymentDialogVisible(true), 0)
    }

    const handleWithdrawButtonClick = (paymentMethod) => {
        selectPaymentMethod(paymentMethod);
        setTimeout(() => setPayoutDialogVisible(true), 0)
    }

    return (
        <StyledClientBalancesContainer>
            <StyledClientBalancesTitle>{tO("availableBalance")}</StyledClientBalancesTitle>
            {(!isLoading && filteredPaymentMethods.length !== 0 && paymentMethods.length > 0) ? <>
                <StyledClientBalancesList>
                    {filteredPaymentMethods.map((method) => (
                        <StyledClientBalancesListItem key={method?.id} onMouseEnter={() => setHoveredId(method?.id)}
                                                      onMouseLeave={() => setHoveredId(null)}>
                            <StyledPaymentMethodsIcons width={20} height={20} mt={10} mb={10} mr={10}
                                                       className={`payment-method-${method?.currency?.asset}`}/>
                            <span>{method?.balances?.amount}</span>
                            {hoveredId === method?.id && (
                                <StyledClientBalancesBtnBlock>
                                    <StyledButton
                                        color="success"
                                        onClick={() => handleDepositButtonClick(method)}
                                    >
                                        {t("fillIn")}
                                    </StyledButton>
                                    <StyledButton
                                        color="danger"
                                        onClick={() => handleWithdrawButtonClick(method)}
                                    >
                                        {t("bringOut")}
                                    </StyledButton>
                                </StyledClientBalancesBtnBlock>
                            )}
                        </StyledClientBalancesListItem>
                    ))}
                </StyledClientBalancesList>
                <StyledClientEstimatedBlock>
                    <StyledClientBalancesListItem>
                        <span>{tO("estimatedValue")}</span>
                        <span>{estimatedValue.toFixed(8)} USDT</span>
                    </StyledClientBalancesListItem>
                </StyledClientEstimatedBlock>
            </> : <Spinner margin={"20px auto"} size="30px"/>}
        </StyledClientBalancesContainer>
    )
}

export default ClientDetailsMainInfo;