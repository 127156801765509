import Dialog from "rc-dialog";
import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    ConfirmationPayoutModalContainer, LeftArrowIcon,
    Line,
    PayoutPaymentInfoContainer,
    PayoutPaymentInfoItem,
    PayoutPaymentInfoTitle,
    PayoutPaymentInfoValue,
    PayoutValue,
    PayoutValueContainer,
    PayoutValueTitle, ReloadFeeButton
} from "./styledConfirmationPayoutModal";
import Big from "big.js";
import ReCaptcha from "../../reCaptcha/ReCaptcha";
import AlertMessage from "../../alert/Alert";
import {StyledButton} from "../../../styles/styledButton";
import reloadFeeIcon from "../../../../assets/images/icons/reload-fee-icon.svg";
import leftArrowIcon from "../../../../assets/images/icons/left-arrow.svg";
import InputGroup from "../../inputGroup/InputGroup";
import {AppContext} from "../../../../App";
import {closableNotification} from "../../notification/ClosableNotification";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {responseStatus} from "../../../../utils/consts";
import http from "../../../../http";
import LoadButton from "../../spinner/ButtonSpinner";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";

function ConfirmationPayoutModal({visible, setVisible, payoutData, closePayoutConfirmationDialog, setPayoutData}) {
    const {t} = useTranslation('siteOptions');
    const {t: tE} = useTranslation('errors');

    const recaptchaRef = useRef(null);
    const [codeActive, setCodeActive] = useState("");
    const [isLoadingUpdateFee, setIsLoadingUpdateFee] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { twoFaIsActive } = useContext(AppContext);
    const { setPayoutDialogVisible } = useBetween(BalanceStates);

    const sortNetworks = (networks) => {
        return networks.sort((a, b) => {
            if (!a.enabled && b.enabled) return 1;
            if (a.enabled && !b.enabled) return -1;

            return a.payoutFee?.constant - b.payoutFee?.constant;
        });
    };

    const createPayoutInvoice = (event) => {
        event.preventDefault();
        const captchaToken = recaptchaRef.current.getValue();

        if (twoFaIsActive && !codeActive) {
            return closableNotification(tE("Enter the two-factor authentication code"), "error");
        }
        if (!captchaToken) {
            return closableNotification(tE("Failed authentication, suspicious activity"), "error");
        }

        setIsLoading(true);

        const data = {
            ...payoutData,
            paymentMethod: `/api/payment-methods/${payoutData?.paymentMethod?.id}`,
            amount: payoutData?.amount?.toString(),
            captchaToken: captchaToken,
            payoutFee: payoutData?.paymentMethod?.payoutFee?.constant,
        };

        recaptchaRef.current.reset();

        if (twoFaIsActive && payoutData?.paymentMethod?.enabled) data.code = codeActive;

        axios.post("/api/payouts", data, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_CREATED) {
                closableNotification(t("withdrawalSuccessfully"), "success");
                setPayoutData((prevState) => ({
                    amount: 0,
                    paymentMethod: prevState.paymentMethod,
                    attributes: [],
                    code: codeActive ?? null,
                    payoutFee: null
                }));
                setIsLoading(false);
                closePayoutConfirmationDialog();
            }
        }).catch(error => {
            if (error.response.status === responseStatus.HTTP_ERROR_VALIDATION) {
                closableNotification(error.response.data.error, "error");
                if (error.response.data.error.startsWith("Payout fee has been changed on")) {
                    const numberMatch = error.response.data.error.match(/\d+(\.\d+)?/);
                    if (numberMatch) {
                        setPayoutData((prevState) => ({...prevState, paymentMethod: {...prevState.paymentMethod, payoutFee: {...prevState.paymentMethod.payoutFee, constant: parseFloat(numberMatch[0])}}}));
                    }
                }
                setIsLoading(false);
            }
        });
    };

    const updatePaymentInfo = () => {
        if (isLoadingUpdateFee) return;
        setIsLoadingUpdateFee(true);
        http.get(`/api/payment-methods?currency.asset=${payoutData?.paymentMethod?.currency?.asset}`, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                const methods = response.data["hydra:member"];
                const sortedMethods = sortNetworks(methods);
                setPayoutData((prevState) => ({...prevState, paymentMethod: sortedMethods.find(obj => obj?.name_view === payoutData?.paymentMethod?.name_view)}));
            }
        }).catch(error => {
            closableNotification(error.response.data.error, "error");
        }).finally(() => {
            setIsLoadingUpdateFee(false);
        });
    }

    return (
        <Dialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => closePayoutConfirmationDialog()}
            animation="zoom"
            maskAnimation="fade"
            title={t("confirmPayout")}
            forceRender={false}
            className="default-modal"
            destroyOnClose={true}
            footer={isLoading ? <LoadButton
                text={t("confirmWithdrawal")}
                color="neutral"
                type="submit"
                style={{width: '100%'}}
            /> : <StyledButton
                onClick={createPayoutInvoice}
                color="neutral"
                type="submit"
                width="100"
            >{t("confirmWithdrawal")}</StyledButton>}
        >
            <ConfirmationPayoutModalContainer>
                <LeftArrowIcon onClick={() => {
                    setPayoutDialogVisible(true);
                    setTimeout(() => setVisible(false), 0);
                }}>
                    <img src={leftArrowIcon} alt="back arrow" />
                </LeftArrowIcon>
                <PayoutValueContainer>
                    <PayoutValueTitle>{t("youReceive")}</PayoutValueTitle>
                    <PayoutValue>{(new Big(payoutData?.amount || 0).minus(new Big(payoutData?.paymentMethod?.payoutFee?.constant || 0))).toFixed(8)} {payoutData?.paymentMethod?.currency?.asset}</PayoutValue>
                </PayoutValueContainer>
                <Line />
                <PayoutPaymentInfoContainer>
                    <PayoutPaymentInfoItem>
                        <PayoutPaymentInfoTitle>{t("network")}</PayoutPaymentInfoTitle>
                        <PayoutPaymentInfoValue>{payoutData?.paymentMethod?.name_view}</PayoutPaymentInfoValue>
                    </PayoutPaymentInfoItem>
                    <PayoutPaymentInfoItem>
                        <PayoutPaymentInfoTitle>{t("address")}</PayoutPaymentInfoTitle>
                        <PayoutPaymentInfoValue>{payoutData?.attributes?.find(item => item.name === 'wallet')?.value}</PayoutPaymentInfoValue>
                    </PayoutPaymentInfoItem>
                    {payoutData?.paymentMethod?.tagId && <PayoutPaymentInfoItem>
                        <PayoutPaymentInfoTitle>{t("memo")}</PayoutPaymentInfoTitle>
                        <PayoutPaymentInfoValue>{payoutData?.attributes?.find(item => item.name === 'tag')?.value ?? "None"}</PayoutPaymentInfoValue>
                    </PayoutPaymentInfoItem>}
                    <PayoutPaymentInfoItem>
                        <PayoutPaymentInfoTitle>{t("withdrawalAmount")}</PayoutPaymentInfoTitle>
                        <PayoutPaymentInfoValue>{payoutData?.amount} {payoutData?.paymentMethod?.currency?.asset}</PayoutPaymentInfoValue>
                    </PayoutPaymentInfoItem>
                    <PayoutPaymentInfoItem>
                        <PayoutPaymentInfoTitle>{t("networkFee")} <ReloadFeeButton onClick={updatePaymentInfo} isAnimate={isLoadingUpdateFee}><img src={reloadFeeIcon} alt={"reload fee icon"}/></ReloadFeeButton></PayoutPaymentInfoTitle>
                        <PayoutPaymentInfoValue>{payoutData?.paymentMethod?.payoutFee?.constant} {payoutData?.paymentMethod?.currency?.asset}</PayoutPaymentInfoValue>
                    </PayoutPaymentInfoItem>
                    {twoFaIsActive ? <InputGroup
                        id="twoFaCode"
                        label={t("inputKey")}
                        name="twoFaCode"
                        value={codeActive}
                        handleChange={(event) => setCodeActive(event.target.value)}
                        type="text"
                        maxLength="6"
                        placeholder={t("inputKeyP")}
                        autocomplete="off"
                        style={{marginBottom: "-17px"}}
                    /> : null}
                    <ReCaptcha recaptchaRef={recaptchaRef}/>
                    <AlertMessage
                        type="warning"
                        message={t("confirmPayoutAlert")}
                    />
                </PayoutPaymentInfoContainer>
            </ConfirmationPayoutModalContainer>
        </Dialog>
    )
}

export default ConfirmationPayoutModal