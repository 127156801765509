import {useContext} from "react";
import {useTranslation} from 'react-i18next';
import {StyledRadioToggler} from "./styledRadioToggler";
import {AppContext} from "../../../App";

const RadioToggler = ({game}) => {
    const {t} = useTranslation('siteOptions');
    const { radio } = useContext(AppContext);
    const {setRadioVisible, isPlayRadio} = radio;

    return (
        <StyledRadioToggler onClick={() => setRadioVisible(((prevState) => !prevState))} radioDisabled={isPlayRadio}>
            <span className="icon radio-icon"/>
            <span className="text">{t("radioTitle")}</span>
        </StyledRadioToggler>
    );
};

export default RadioToggler;