import bgGameDark from "../../../assets/images/backgrounds/bg-game__dark.svg";
import bgGameMobileDark from "../../../assets/images/backgrounds/bg-game-mobile__dark.svg";
import bgTemplatePageDark from "../../../assets/images/backgrounds/bg-template__dark.svg";
import bgBallsDark from "../../../assets/images/backgrounds/bg-balls__dark.svg";
import bgBallsMobileDark from "../../../assets/images/backgrounds/bg-balls-mobile__dark.svg";
import bgRouletteDark from "../../../assets/images/backgrounds/bg-roulette__dark.svg";
import bgRouletteMobileDark from "../../../assets/images/backgrounds/bg-roulette-mobile__dark.svg";
import bgDiceHistoryDark from "../../../assets/images/backgrounds/bg-dice-history__dark.svg";
import bgRouletteHistoryDark from "../../../assets/images/backgrounds/bg-roulette-history__dark.svg";
import bgBallsHistoryDark from "../../../assets/images/backgrounds/bg-balls-history__dark.svg";
import rollDark from "../../../assets/images/roll/roll__dark.png";
import gamesDiceDark from "../../../assets/images/leftSidebar/games/dice__dark.webp";
import gamesRouletteDark from "../../../assets/images/leftSidebar/games/roulette__dark.webp";
import gamesBallsDark from "../../../assets/images/leftSidebar/games/balls__dark.webp";
import gamesBallsMagnetDark from "../../../assets/images/balls/magnet__dark.png";
import gamesBallsItemBgDark from "../../../assets/images/balls/balls__dark.png";
import gamesBallsMobileItemBgDark from "../../../assets/images/balls/balls-mobile__dark.png";
import gamesBallsItemBgDarkActive from "../../../assets/images/balls/balls__dark_active.png";
import bgIdDice from "../../../assets/images/backgrounds/pagebg-dice-dark.svg";
import bgIdBalls from "../../../assets/images/backgrounds/pagebg-balls-dark.svg";
import bgIdRoulette from "../../../assets/images/backgrounds/pagebg-roulette-dark.svg";
import bgCheckBoxRadio from "../../../assets/images/checkboxradio__dark.png";
import jackpotDice from "../../../assets/images/jackpot/jackpot-dice.svg";
import jackpotBalls from "../../../assets/images/jackpot/jackpot-balls.svg";
import jackpotRoulette from "../../../assets/images/jackpot/jackpot-roulette.svg";
import settingsIconDark from "../../../assets/images/buttonIcons/settings-icon-dark.svg";
import settingsIconLight from "../../../assets/images/buttonIcons/settings-icon-light.svg";
import headerMailImg from "../../../assets/images/chat/messages_icon_dark.svg";
import headerUsersImg from "../../../assets/images/chat/friends_icon_dark.svg";
import userTagImg from "../../../assets/images/user_icon_dark.svg";
import betTagImg from "../../../assets/images/bet_icon_dark.svg";
import miningTimeImg from "../../../assets/images/mining/timer-wh.png";
import miningInviteImg from "../../../assets/images/mining/ppl-bl.png";
import addToFriendsImg from "../../../assets/images/account/add_friend_icon_dark.svg";
import sendMessageImg from "../../../assets/images/account/messages_icon_dark.svg";
import sendTipImg from "../../../assets/images/account/sendtips_icon_dark.svg";
import {StyledSliderBackBar} from "../../elements/autoBets/styledAutoBets";

export default {
  mainTextColor: "#777777",
  secondTextColor: "#9f9f9f",
  subTitleColor: "#ffffff",
  defaultChangeColor: "#058bd8",
  body: "#0e0e0e",
  text: "#ffffff",
  headerBg: "#050505",
  footerBg: "#0A0A0A",
  footerColor: "#777777",
  neutralColor: "#222222",
  neutralColorHover: "#2b2b2b",
  scrollBackground: "#333333",
  scrollTrack: "#171717",
  scrollThumb: "#333333",
  scrollThumbHover: "#555555",
  optionButtonsBg: "#1e1e1e",
  optionOpacity: "0.4",
  optionOpacityHoverContainer: "0.6",
  optionBackgroundHover: "#1e1e1e",
  historyLink: "#9f9f9f",
  historyLinkHover: "#FFFFFF",
  optionButtonsBgHover: {
    "background-color": "#1e1e1e;",
    "opacity": "1;"
  },
  optionButtonsColor: "#9f9f9f",
  elementBg: "#111111",
  grayForHeader: "#ffffff",
  switchTheme: "center right",
  jackpot: {
    "filter": " none;",
    "opacity": "0.7;"
  },
  paymentMethodSearch: {
    borderColor: "#222222",
    color: "#ffffff",
    opacity: "0.6",
    mobileNav: {
      backgroundColor: "#202020",
      color: "#ffffff"
    }
  },
  paymentMethodConfig: {
    background: `#191919 url(${settingsIconDark}) no-repeat 50% 50%`,
    opacity: 0.6
  },
  paymentMethodConfigMobile: {
    background: `transparent url(${settingsIconLight}) no-repeat 50% 50%`,
    opacity: 0.6
  },
  paymentMethodNothingFound: {
    backgroundColor: "#151515"
  },
  paymentMethod: {
    "opacity": "0.4;",
    "color": "#ffffff"
  },
  paymentMethodMobileBgColor: "#111111",
  paymentMethodActive: {
    "background-color": "#1b1b1b;",
    "opacity": "1;"
  },
  paymentMethodHover: {
    "background-color": "transparent;",
    "opacity": "1;"
  },
  currenciesSelect: {
    "background-color": "#141414;",
  },
  currenciesOption: {
    "color": "#999999;"
  },
  //logo
  logo: {
    "opacity": ".7;"
  },
  logoHover: {
    "opacity": "1;"
  },
  //sendTip
  sentTipPaymentItem: {
    backgroundColor: "#222222",
    opacity: 0.5
  },
  sentTipPaymentItemActive: {
    borderBottom: "1px #058bd8 solid",
    background: "#333333",
    opacity: 1,
  },
  //betActions
  betActions: {
    "color": "#ffffff;"
  },
  //siteOptions
  grayColorText: "#9f9f9f",
  //account
  accountTextShadow: "1px 2px 3px rgba(0, 0, 0, 1)",
  accountAvatarBorderColor: "#222222",
  accountAvatarShadow: "3px 3px 10px rgba(0, 0, 0, 1)",
  accountInfoFooterColor: "#131313",
  accountActivityItemBg: "#161616",
  accountActivityItemTextColor: "#ffffff",
  accountActivityRowBg: "#161616",
  accountActivityRowBorder: "none",
  accountActivityItemShadow: "0px 2px 3px rgb(0, 0, 0, 0.8)",
  accountActivityImgShadow: "2px 3px 5px rgba(0, 0, 0, 1)",
  accountImages: {
    addToFriends: addToFriendsImg,
    sendMessage: sendMessageImg,
    sendTip: sendTipImg
  },
  accountFriends: {
    backgroundColor: "#111111",
    border: "1px #181818 solid",
    item: {
      level: {
        backgroundColor: "#000000",
        color: "#ffffff",
        border: "2px solid #333333",
        boxShadow: "-1px 1px 5px rgba(0, 0, 0, 0.8)"
      }
    },
    title: {
      color: "#777777",
      counterColor: "#ffffff"
    }
  },
  accountBalances: {
    bgItem: "rgba(20, 20, 20, 0.7)",
    bgItemBtn: "#131313"
  },
  //chat
  colorsChat: {
    backgroundColor: "#171717",
    backgroundColorBot: "#1e1e1e",
    bgColorBotTitle: "#c77d12",
    colorMessage: "#7a7a7a",
    colorTime: "#555555",
    colorUser: "#ffffff",
    borderColor: "#050505",
    backgroundEmoji: "#272727",
    backgroundInfo: "#151515",
    buttonBackground: "#111111",
    backgroundInputText: "#1b1b1b",
    tag: {
      color: '#9f9f9f',
      hoverColor: '#ffffff',
      userImage: `url(${userTagImg}) no-repeat center center`,
      betImage: `url(${betTagImg}) no-repeat center center`,
      currentColor: '#2eab5b',
      botName: "#eaeaea"
    }
  },
  autoBet: {
    bgModal: "#111111",
    slider: "#101010",
    point: "transparent",
    backgroundInfo: "#151515",
  },
  withdrawBtn: "#565656",
  //gamesDice
  diceBox: {
    "color": "#999999",
    "background-color": "rgba(0, 0, 0, 0.6)",
    "border": "5px solid #000000"
  },
  diceBoxShadow: {
    "background": "rgba(0, 0, 0, .5);",
    "box-shadow": "0px 0 15px 10px rgba(0, 0, 0, .6)"
  },
  rollTableShadow: '0px 0 15px 10px rgba(0, 0, 0, .4)',
  rollTableShadowBackground: 'rgba(0, 0, 0, .4)',
  rollMobileTableShadow: '0px 0 15px 10px rgba(0, 0, 0, .3)',
  rollMobileTableShadowBackground: 'rgba(0, 0, 0, .3)',
  rollShadow: '0px 0 15px 10px rgba(0, 0, 0, .3)',
  rollShadowBackground: 'rgba(0, 0, 0, .3)',
  rollMobileShadow: '0px 0 15px 10px rgba(0, 0, 0, .3)',
  rollMobileShadowBackground: 'rgba(0, 0, 0, .3)',
  diceBoxLine: "#000000",
  diceHistory: {
    progressBg: "#000000",
    numBg: "#11492f",
    numColor: "#FFFFFF",
    win: "#2eab5b",
    lose: "#ab2e40"
  },
  diceNumber: {
    win: "#2eac5b",
    lose: "#ab2e40",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  //gamesBalls
  ballsMagnet: gamesBallsMagnetDark,
  ballsItem: gamesBallsItemBgDark,
  ballsMobileItem: gamesBallsMobileItemBgDark,
  ballsItemActive: gamesBallsItemBgDarkActive,
  ballsBox: {
    "color": "#999999",
    "background-color": "rgba(0, 0, 0, 0.6)",
    "border": "5px solid #000000"
  },
  ballsBoxLine: "#000000",
  //roulette
  roll: rollDark,
  rollTableBackground: 'rgba(130, 130, 130, 0.1)',
  rollTableBorder: "#000000",
  rollTableHover: "rgba(150, 150, 150, 0.3)",
  rollTableSplitHover: "rgba(42, 42, 42, 0.75)",
  rouletteBackDown: "rgba(20, 20, 20, 0.4)",
  rouletteRowBackDown: "rgba(30, 30, 30, 0.3)",

  rollTableItemInnerDivRedBackground: '#ab2e40',
  rollTableItemInnerDivBlackBackground: '#000000',
  //LeftSidebar
  leftSidebar: {
    blockTitleColor: "#FFFFFF",
    blockBgColor: "#111111",
    blockBgLightColor: "#171717",
    blockBorderLightColor: "#1b1b1b",
    blockBorderColor: "#181818",
    statisticBlock: {
      backgroundColor: "#151515"
    },
    resetIcon: {
      "filter": "brightness(1.8)"
    },
    labelStatistic: "#999999",
    closeBtn: {
      color: "#ffffff"
    },
    input: {
      backgroundColor: "#202020",
      color: "#ffffff"
    }
  },
  customSelect: {
    blockBorderColor: "#181818",
    blockHover: "#1b1b1b",
    bgList: "#0e0e0e",
    arrow: "#999"
  },
  leftSidebarContainer: {
    "background-color": "#111111",
    "box-shadow": "0px 4px 8.5px 0.5px rgb(46 46 46 / 50%)"
  },
  leftSidebarActions: {
    bgColor: "rgba(34, 34, 34, 0.5)",
    hoverBgColor: "#222222",
    opacityIcon: "0.5",
    hoverOpacityIcon: "0.75"
  },
  leftSidebarGames: {
    dice: gamesDiceDark,
    roulette: gamesRouletteDark,
    balls: gamesBallsDark,
    titleColor: "#FFFFFF",
    titleGradientColor: "linear-gradient(360deg, #7c807e, #FFFFFF)"
  },
  leftSidebarChat: {
    header: {
      borderColor: "#222222"
    },
    headerActionItem: {
      borderColor: "#111111"
    },
    headerMailImg: headerMailImg,
    headerUsersImg: headerUsersImg,
    lang: {
      backgroundColor: "#ffffff",
      color: "#111111"
    },
  },
  leftSidebarRooms: {
    item: {
      backgroundColor: "#171717",
      color: "#999999"
    },
    itemHover: {
      backgroundColor: "#222222"
    }
  },
  leftHonestyControl: {
    linkHoverColor: "#ffffff"
  },
  readingPage: {
    bgColor: "#161616cc",
    color: "#777777",
    borderColor: "#222222",
  },
  //Jackpot
  scrollbar: "#484a49",
  scrollbarThumb: "#1c1e1d",
  diceColor: "#11341e",
  ballsColor: "#1e1c2f",
  rouletteColor: "#2c1417",
  blockBgColor: "rgba(23, 23, 23, 0.5)",
  //Input
  inputColor: "#ffffff",
  inputBgColor: "#131313",
  inputBorderColor: "#222222",
  inputBorderColorDisabled: "#131313",
  inputBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 80%);",
  labelColor: "#797778",
  readOnlyInput: "#b0b0b0",
  //CustomSelectPayment
  customOptionBg: "#0e0e0e",
  activeBgOption: "#1b1b1b",
  customOptionBorder: "#1b1b1b",
  //AlertMessage
  alertMessageBg: "#171717",
  dottedAlertBorder: "#242424",
  //UploadAvatar
  lightBackground: "#171717",
  borderUploadAvatar: "#242424",
  opacityUploadAvatar: "1",
  //Table
  tableRow: {
    color: "#5F5F5F",
    backgroundColor: "#141414",
    borderColor: "#050505",
    borderWidth: 1
  },
  partnerItem: {
    backgroundColor: "rgba(20, 20, 20, 0.6)",
    borderColor: "#181818",
  },
  tabs: {
    textColorActive: "#FFFFFF"
  },
  lightTableBackground: "#141414",
  //Notification
  notification: {
    bgColor: "#222222",
    fontColor: "#FFFFFF",
    shadowColor: "0 0 10px 0 rgb(0 0 0 / 75%)",
    closeBtnColor: "#FFFFFF",
    iconColor: {
      error: "#AA2E41",
      success: "#2eab5b",
      warning: "#c4863b",
      info: "#307cad",
      bgColor: "#2F2F2F"
    }
  },
  control: {
    code: {
      fontColor: "#9f9f9f",
      fontColorFunc: "#FFFFFF"
    }
  },
  authBtn: {
    backgroundColor: "transparent",
    borderColor: "#797778",
    backgroundHover: "#2b2b2b",
    borderHover: "#797778"
  },
  //Back checkbox-input
  backgroundCheckboxInput: bgCheckBoxRadio,
  //Backgrounds
  bgGame: bgGameDark,
  bgMobileGame: bgGameMobileDark,
  bgTemplatePage: bgTemplatePageDark,
  bgBalls: bgBallsDark,
  bgBallsMobile: bgBallsMobileDark,
  bgRoulette: bgRouletteDark,
  bgRouletteMobile: bgRouletteMobileDark,
  bgDiceHistory: bgDiceHistoryDark,
  bgRouletteHistory: bgRouletteHistoryDark,
  bgBallsHistory: bgBallsHistoryDark,
  bgIdDice: bgIdDice,
  bgIdBalls: bgIdBalls,
  bgIdRoulette: bgIdRoulette,
  jackpotDice: jackpotDice,
  jackpotBalls: jackpotBalls,
  jackpotRoulette: jackpotRoulette,
  mining: {
    timeIcon: miningTimeImg,
    inviteImg: miningInviteImg,
    infoBg: "#1b1b1b",
    timer: {
      alternateBg: "#222222",
      defaultBg: "#171717"
    }
  },
  twoFa: {
    codeBg: "#151515"
  },
  notifications: {
    button: {
      backgroundColor: "#222425"
    },
    body: {
      backgroundColor: "#222425",
      borderColor: "#013a5b"
    },
    time: {
      backgroundColor: "#171819"
    }
  },
  closeIconColor: "#9f9f9f",
  staking: {
    backgroundColor: "#19191919",
    infoBlock: {
      backgroundColor: "#171717",
      mainTextColor: "#9f9f9f",
      textColor: "#777777"
    },
    tabs: {
      textColor: "#eeeeee"
    },
    search: {
      backgroundColor: "#151515",
      textColor: "#777777"
    },
    table: {
      mainTextColor: "#777777",
      textColor: "#4f4f4f",
      mainTextFontWeight: 400,
      tableRow: {
        backgroundColorEven: "#151515",
        backgroundColorOdd: "#191919",
        backgroundColorCollapse: "#111111",
      },
      period: {
        borderColor: "#484848",
        backgroundColor: "#1b1b1b",
        backgroundColorActive: "#171616",
        textColor: "#4f4f4f",
        borderNone: true
      },
      timer: {
        backgroundColor: "#0b0b0b"
      }
    },
    progress: {
      backgroundColor: "#131313",
      borderColor: "#222222", // #2f7bad
      progressColor: "#1c272f",
      mainTextColor: "#ffffff",
      textColor: "#7d7d7d",
    }
  },
  store: {
    item: {
      backgroundColor: "rgba(30, 30, 30, 0.7)"
    }
  },
  dialogMask: {
    backgroundColor: "rgba(0, 0, 0, 0.6)"
  },
  mobileNav: {
    item: {
      backgroundColor: "#171717",
      color: "#9F9F9F"
    },
    itemHover: {
      backgroundColor: "#222222",
      color: "#ffffff"
    },
    closeBtn: {
      backgroundColor: "#ffffff"
    },
    navBlock: {
      opacity: 0.5,
      backgroundColor: "rgba(34, 34, 34, 0.5)",
      bgHover: "rgba(34, 34, 34, 1)",
      textOpacity: 0.5,
      opacityHover: 1,
    },
    mobNavBlock: {
      opacity: 0.6,
      backgroundColor: "#171717",
      bgHover: "#222222",
      textOpacity: 0.6,
      opacityHover: 1,
    }
  },
  tokens: {
    days: {
      backgroundColor: "#1b1b1b",
      filled: "#222222",
      notFilled: "#111111",
      amountCurrDay: "#ffffff",
      amountOtherDay: "#707070",
      headerDayBackground: "linear-gradient(to right, #222222 0%, #000000 50%, #222222 100%)"
    },
    notificationAmountToken: {
      textColor: "#6c6a6b",
      backgroundColor: "#171717",
      iconColor: "#ffffff",
      iconBackground: "#2a2a2a"
    },
    popupAuthenticator: {
      backgroundColor: "#171717",
      textColor: "#797778",
    }
  },
  userLevel: {
    background: "#161616",
    color: "#ffffff",
    starBg: "#2eab5b",
    levelColor: "#2eab5b",
    desColor: "#777777",
    border: "#161616"
  },
  userLevelItem: {
    border: "#181818",
    background: "#111111",
    titleColor: "#ffffff",
    pointsColor: "#ffffff",
    percentBg: "#0d0d0d",
    percentLine: "#ffffff",
    percentColor: "#2eab5b",
    descColor: "#777777"
  },
  accountSettingLink: {
    color: "#FFFFFF"
  },
  transactionHist: {
    nothingBg: "rgba(20, 20, 20, 0.8)",
    nothingTitle: "#777777",
    itemBg: "rgba(22, 22, 22, 0.8)",
    itemColor: "#777777",
    itemTitle: "#c9c9c9",
    infoBg: "#0f0f0f",
    infoTitle: "#777777",
    infoSp: "#9f9f9f",
    infoBd: "#9f9f9f",
    red: "#ab2e40"
  },
  bonusGame: {
    titleForm: "#ffffff",
    textSec: "#c5c5c5",
    textMain: "#9f9f9f",
    link: "#ffffff",
    tableItem: "#132721",
    blockBg: "rgba(0, 0, 0, 0.4)"
  },
  bonusRules: {
    background: "#151515",
    limitText: "rgba(170, 170, 170, 0.75)"
  }
};
