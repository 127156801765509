import styled from "styled-components";
import editIcon from "../../../assets/images/icons/edit-icon.svg";
import Dialog from "rc-dialog";
import { device, media } from "../../styles/responsive/responsiveUtils";

export const StyledWelcomeContent = styled.div`
  position: relative;
  padding-bottom: 25px;

  .information__site {
    padding-bottom: 10px;
    display: flex;
    color: #777777;
    font-size: 14px;
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 0;
    height: 53px;

    span {
      color: ${({ theme }) => theme.defaultChangeColor};
      font-size: 22px;
      font-weight: 500;
    }

    &.bet {
      left: 0;
      width: 30%;
    }

    &.bet, &.wins {
      align-items: center;
      top: 10px;

      img {
        width: 15px;
        height: 15px;
        margin: 3px 0 0 4px;
        user-select: none;
      }
    }

    &.bet span {
      margin-right: 13px;
    }

    &.wins span {
      margin-left: 13px;
    }
  }

  .user-info {
    color: ${({ theme }) => theme.text};
    font-size: ${({ isAuth }) => isAuth ? "32px" : "24px"};
    text-transform: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-shadow: ${({ theme }) => theme.accountTextShadow};
    line-height: normal;  

    &__level {
      width: 22px;
      height: 22px;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -4px;
      right: -4px;
      border: 2px solid #215be3;
      background-color: ${({ theme }) => theme.accountActivityItemBg};
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
    }

    &__image {
      width: 45px;
      height: 45px;
      margin: 0 8px 0 15px;
      border: none;
      border-radius: 50%;
      position: relative;

      &:before {
        content: "";
        width: 45px;
        height: 45px;
        background: rgba(0, 0, 0, 0.4) url(${editIcon}) 50% 50% no-repeat;
        background-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        cursor: pointer;
        border-radius: 45px;
        opacity: 0;
        transition: all .3s ease;
      }

      &:not(:has(img)):after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(127, 127, 127, 0.3) 50% 50% no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 45px;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        border-radius: 100%;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    span {
      padding-left: ${({ isAuth }) => isAuth ? "0" : "7px"};
      font-size: ${({ isAuth }) => isAuth ? "32px" : "24px"};
      font-weight: unset;
    }
  }

  @media screen and (max-width: 1100px) {
    display: none;
    padding-bottom: 0;
  }
`;

export const StyledSiteOptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;

  .panel {
    display: flex;
    align-items: center;
    ${({ isMobile }) => isMobile ? '' : 'position: absolute;'}
    top: 0;
    left: 0;
    @media screen and ${device('desktop')} {
      gap: 8px;
    }

    &__item {
      height: 30px;
      color: #ffffff;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.optionButtonsBg};
      transition: all 0.3s ease-out;

      @media screen and ${device('desktop', 'min')} {
        opacity: ${({ theme }) => theme.optionOpacity};
        margin-right: 1px;
        
        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
      
       @media screen and ${device('desktop')} {
         opacity: .5;
         border-radius: 5px;
       }

      .icon {
        width: 13px;
        height: 11px;

        display: inline-block;
        background-repeat: no-repeat;
        transition: all 0.15s ease-in-out;
        opacity: 0.6;
        ${media('xm', 'min').css`
          margin-right: 5px;
        `}
      }

      button {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 5px 15px 6px;
        font-size: 12px;
        text-transform: uppercase;
        border: none;
        outline: none;
        
        @media screen and ${device('xm')} {
          padding: 6px 10px;
        }
      }

      &:hover {
        color: #FFFFFF;
        ${({ theme }) => theme.optionButtonsBgHover};
      }
    }

    ${({ isMobile }) => isMobile ? `` : `
    @media screen and (max-width: 1100px) {
      position: static;
      top: unset;
      left: unset;
      margin-bottom: 10px;
    }
    `}
  }

  .jackpot {
    height: 30px;
    padding: 5px 15px 6px;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    opacity: ${({ theme }) => theme.optionOpacity};
    background: ${({ theme }) => theme.optionButtonsBg};
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    margin-right: -124px;
    white-space: nowrap;

    .tether {
        height: 11px;
        width: auto;
        margin-left: 6px;
    }      

    @media screen and ${device('desktop')} {
      opacity: .5;  
      margin-right: 0;
    }


    &__img {
      display: block;
      margin-right: 6px;
      ${({ theme }) => theme.jackpot}
    }

    &:hover {
      color: #FFFFFF;
      ${({ theme }) => theme.optionButtonsBgHover};
    }
  }
    
  .roulette_panel {
    display: flex;
    align-items: center;
    ${({ isMobile }) => isMobile ? '' : 'position: absolute;'}
    top: 0;
    right: 0;
    @media screen and ${device('desktop')} {
      gap: 8px;
    }

    &__item {
      height: 30px;
      color: #ffffff;
      display: flex;
      align-items: center;
      background: ${({ theme }) => theme.optionButtonsBg};
      transition: all 0.3s ease-out;

      @media screen and ${device('desktop', 'min')} {
        opacity: ${({ theme }) => theme.optionOpacity};
        margin-left: 1px;
        
        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }
      
       @media screen and ${device('desktop')} {
         opacity: .5;
         border-radius: 5px;
       }

      button {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 5px 15px 6px;
        font-size: 12px;
        text-transform: uppercase;
        border: none;
        outline: none;
        
        @media screen and ${device('xs')} {
          padding: 6px 10px;
        }
      }

      &:hover {
        color: #FFFFFF;
        ${({ theme }) => theme.optionButtonsBgHover};
      }
    }

    ${({ isMobile }) => isMobile ? `` : `
    @media screen and (max-width: 1100px) {
      position: static;
      top: unset;
      left: unset;
      margin-bottom: 10px;
    }
    `}
  }  

  ${({ isMobile }) => isMobile ? `` : `
  @media screen and (max-width: 1100px) {
    margin-bottom: 12px;
    flex-wrap: wrap;
    .roulette_panel {
      width: 100%;
      justify-content: center;
    }
    .jackpot {
      margin-bottom: 8px;
      margin-left: 8px;
      span {
        display: none;
      }
    }
    .panel {
      margin-bottom: 8px;
    }
  }
  
  @media screen and (max-width: 570px) {
    .panel .text {
      display: none !important;
    }
    .panel button {
      padding: 6px 10px;
      .icon {
        margin-right: 0;
      }
    }
  }    
  `}
`;

export const StyledJackpotDialog = styled(Dialog)`
  &.default-modal-jackpot {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 6px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    & .rc-dialog-content {
        background: url(${({
                               theme,
                               page
                           }) => page === 'dice' ? theme.jackpotDice : page === 'balls' ? theme.jackpotBalls : theme.jackpotRoulette}) no-repeat center top;

      overflow: hidden;
      background-color: ${({
        theme,
        page
      }) => page === 'dice' ? theme.diceColor : page === 'balls' ? theme.ballsColor : theme.rouletteColor};

      .rc-dialog-body {
        background: none;
      }
      
      .rc-dialog-header {
          border-bottom: none;
          box-shadow: none;
      }  
    }
  }
`;

export const StyledJackpotWrapper = styled.div`
`;

export const StyledTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0 40px;
  z-index: 2;
  position: relative;

  span {
    color: ${({ page }) => page === "dice" ? "#2eac5b" : page === "balls" ? "#844c94" : "#ab2e40"};
    font-weight: 500;
    display: block;
    padding-top: 5px;
    text-transform: uppercase;
  }

  b {
    text-shadow: 0 3px 2.9px rgb(32 32 32 / 70%);
    font-size: 36px;
    font-weight: 500;
    color: ${({ page }) => page === "dice" ? "#058bd8" : page === "balls" ? "#ab2e40" : "#2eac5b"};
  }
`;

export const StyledTextBlock = styled.div`
  padding: 5px 20px 10px;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}px`};
  background: ${({ theme }) => theme.blockBgColor};
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  overflow: hidden;
  font-size: 15px;
  z-index: 15;
  ${({ transparent }) => !transparent && 'position: relative'};

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 17px;
    padding: 15px 0;
    font-weight: 500;
    position: relative;
    z-index: 2;
  }

  p {
    padding-bottom: 12px;
    position: relative;
    z-index: 2;
  }
    
  &.claim-info-block.bonus-modal-block {
      overflow-y: hidden;
      position: relative;
      transition: all 0.3s ease-in-out;
      overflow-x: hidden;

      .custom-hint {
          position: absolute;
          width: 0;
          transform: translate(30px, -18px);
          z-index: 10000;
      }
  }  
`;

export const StyledStaticTable = styled.div`
  overflow: auto;
  overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
    background-color: ${({ theme }) => theme.body};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    height: 8px;
    border: 2px solid ${({ theme }) => theme.body};
    background-color: ${({ theme }) => theme.neutralColor};

    border-radius: 6px;
  }

  &.jackpot {
      //background: ${({ theme }) => theme.blockBgColor};
    width: calc(100% + 40px);
    margin-left: -20px;
  }
`;

export const StyledTableSimple = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  width: 100%;
`;

export const StyledHead = styled.tr`
  text-transform: uppercase;
  padding: 13px 20px;
  color: ${({ theme }) => theme.labelColor};
`;

export const StyledBody = styled.tbody``;

export const StyledRow = styled.tr``;

export const StyledItem = styled.td`
  text-align: center;
  padding: 15px 13px 15px 0;
  white-space: nowrap;
  border-top: 1px solid ${({ theme }) => theme.diceBoxLine};

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }
`;

export const StyledHeadTitle = styled(StyledItem)`
  border-top: none;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 13px;
`;
