import styled from "styled-components";
import radioIcon from "../../../assets/images/buttonIcons/radio-icon.svg";
import { hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledRadioToggler = styled.button`
    .radio-icon {
            width: 11px !important;
            background: url(${radioIcon}) center ${({radioDisabled}) => radioDisabled ? 'right' : 'left'} no-repeat;
            background-size: 22px 11px;
    } 
        
    @media screen and (max-width: 600px) {
        padding: 6px 11px !important;    
            
        .radio-icon {
            width: 9px !important;
        }
    }

    .text {
        ${hideOnMedia('xm')}
    }
`;

export const StyledRadioPlayer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
        
    iframe {
        width: 400px;
        height: 492px;
        border: none;
    }

    @media screen and (max-width: 360px) {
        iframe {
            width: 100%;
            height: 492px;
            border: none;
        }   
    }
`