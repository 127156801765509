import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { attributeNames, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import InputGroup from "../inputGroup/InputGroup";
import BetAction from "../betAction/BetAction";
import { StyledButton } from "../../styles/styledButton";
import AlertMessage from "../alert/Alert";
import http from "../../../http";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import Select, { Option } from "rc-select";
import { isNumeric } from "rc-drawer/lib/utils";
import { StyledModalLoader } from "../payment/styledPaymentInvoice";
import loadingGif from "../../../assets/images/loading.gif";
import Maintenance from "../maintenance/Maintenance";
import TransactionAttributesTagBlock from "../../payoutInvoice/TransactionAttributesTagBlock";
import {formatNumber} from "../../../utils/formatNumber";
import {validationTag} from "../../payoutInvoice/validations/validations";
import {useBetween} from "use-between";
import BalanceStates from "../../games/BalanceStates";

const PayoutInvoice = ({paymentMethod, setVisible, setConfirmationVisible, payoutSubmitData, setPayoutSubmitData}) => {

  const [transactionAttributes, setTransactionAttributes] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [valueNumber, setValueNumber] = useState();
  const { t } = useTranslation("siteOptions");
  const [loading, setLoading] = useState(true);
  const [displayBalanceError, setDisplayBalanceError] = useState(false);
  const [isValueChanged, setIsvalueChanged] = useState(false);
  const [isMemo, setIsMemo] = useState(false);
  const [isWallets, setIsWallets] = useState(false);

  const {
    confirmationVisible,
    payoutDialogVisible
  } = useBetween(BalanceStates);

  const sortNetworks = (networks) => {
    return networks.sort((a, b) => {
      if (!a.enabled && b.enabled) return 1;
      if (a.enabled && !b.enabled) return -1;

      return a.payoutFee?.constant - b.payoutFee?.constant;
    });
  };

  useEffect(() => {
    if (isMemo) {
      setPayoutSubmitData(prev => {
        return {
          ...prev,
          attributes: prev.attributes.filter(item => item.name !== attributeNames.TAG)
        };
      });
    }
  }, [isMemo]);

  const fetchTransactionAttributes = () => {
    setLoading(true);
    axios.get("/api/transaction-attributes?paymentMethod.id=" + paymentMethod.id + "&transactionType=payout",
      userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        const attributes = response.data["hydra:member"];
        if (Array.isArray(attributes) && attributes.length > 0) {
          const object = {};
          attributes.forEach(item => object[item.name] = item);
          setTransactionAttributes(object);
        } else {
          setTransactionAttributes(null);
        }
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const getPaymentInvoiceData = () => {
    setLoading(true);
    axios.get(`/api/payment/invoice-data/${selectedPaymentMethod?.id}`, userAuthenticationConfig())
        .then(async response => {
          if (response.status === responseStatus.HTTP_OK) {
            setIsWallets(true);
            fetchTransactionAttributes();
          }
        })
        .catch(error => {

        })
  };

  useEffect(() => {
    if (!selectedPaymentMethod?.userWallets && selectedPaymentMethod?.id && selectedPaymentMethod?.enabled) {
      getPaymentInvoiceData();
    }
  }, [selectedPaymentMethod])

  const getPaymentMethodsSystem = () => {
    setLoading(true);
    http.get(`/api/payment-methods?currency.asset=${paymentMethod.currency.asset}`, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        const methods = response.data["hydra:member"];
        const sortedMethods = sortNetworks(methods);
        setPaymentMethods(sortedMethods);
        setSelectedPaymentMethod(sortedMethods[0]);
        setPayoutSubmitData((prevState) => ({...prevState, paymentMethod: sortedMethods[0]}));
      }
    }).catch(error => {
      closableNotification(error.response.data.error, "error");
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleInput = (valueNumber) => {
    const filteredValue = valueNumber.replace(/[^0-9.]/g, '');

    const value = filteredValue.replace(/,/g, '.');

    if (!isNaN(value) && (value.match(/\./g) || []).length <= 1) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart && decimalPart.length > 8) {
        decimalPart = decimalPart.substring(0, 8);
      }
      setValueNumber(decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart);
    } else {
      setValueNumber(value);
    }

    setIsvalueChanged(true);
  };

  const min = useMemo(() => {
    return parseFloat(formatNumber(selectedPaymentMethod?.payoutFee?.min + selectedPaymentMethod?.payoutFee?.constant));
  }, [selectedPaymentMethod]);

  const max = useMemo(() => {
    return parseFloat((formatNumber(selectedPaymentMethod?.payoutFee?.max)));
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (!payoutDialogVisible || confirmationVisible) return;
    window.scrollTo(0, 0);
    fetchTransactionAttributes();
    getPaymentMethodsSystem();
  }, [payoutDialogVisible]);

  useEffect(() => {
    if (payoutDialogVisible || confirmationVisible) return;
    setDisplayBalanceError(false);
    setIsvalueChanged(false);
    setValueNumber(undefined);
    setTransactionAttributes(null);
    setPaymentMethods([]);
    setSelectedPaymentMethod(null);
    setLoading(true);
    setIsMemo(false);
  }, [payoutDialogVisible]);

  const handleChangePaymentSystem = (value) => {
    setSelectedPaymentMethod(paymentMethods.find((paymentMethod) => paymentMethod.id === value));
    setPayoutSubmitData((prevState) => ({...prevState, paymentMethod: paymentMethods.find((paymentMethod) => paymentMethod.id === value)}));
  };

  if (loading || !selectedPaymentMethod || (!selectedPaymentMethod?.userWallets && !transactionAttributes?.tag && !isWallets && selectedPaymentMethod?.enabled)) {
    return <StyledModalLoader>
      <img src={loadingGif} alt={"loading..."}/>
    </StyledModalLoader>;
  }

  const validate = (value, forceValidateBalance = false) => {
    if (!isValueChanged) {
      return false;
    }
    if (!isNumeric(value)) {
      return true;
    }
    value = parseFloat(value);

    if ((displayBalanceError || forceValidateBalance) && selectedPaymentMethod?.balances?.amount) {
      if (value > selectedPaymentMethod?.balances?.amount) {
        return t("insufficientFunds") + `: ${selectedPaymentMethod?.balances?.amount} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    if (selectedPaymentMethod?.payoutFee?.min) {
      if (value < min) {
        return t("minimumAmount") + `: ${min} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    if (selectedPaymentMethod?.payoutFee?.max) {
      if (value > max) {
        return t("maximumAmount") + `: ${max} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    return false;
  };

  const updateAttribute = (attribute, value) => {
    if (attribute.id) {
      const name = `/api/transaction-attributes/${attribute.id}`;

      setPayoutSubmitData(old => {
        const attributes = old.attributes;

        const index = attributes.findIndex(attribute => attribute.attribute === name);

        if (index >= 0) {
          attributes[index].value = value;
        } else {
          attributes.push({ attribute: name, name: attribute.name, value, id: attribute.id });
        }

        old.attributes = attributes;

        return { ...old };
      });
    }
  };

  const openConfirmationModal = () => {
    const amount = parseFloat(payoutSubmitData.amount);
    const walletAddress = payoutSubmitData?.attributes?.find(item => item.name === 'wallet')?.value;

    if (!walletAddress) {
      closableNotification(t("enterWalletAddress"), "error");
      return;
    }

    if (!amount) {
      closableNotification(t("enterSum"), "error");
      return;
    }

    if (transactionAttributes.tag && !isMemo) {
      const element = payoutSubmitData.attributes.find(item => item.id === transactionAttributes["tag"].id);
      const isValidationTagError = validationTag(element?.value, t);

      if (isValidationTagError) {
        return;
      }
    }

    const validationResult = validate(amount, true);

    if (validationResult) {
      setDisplayBalanceError(true);
      closableNotification(validationResult, "error");
      return;
    }

    setPayoutSubmitData((prevState) => ({...prevState, paymentMethod: {...prevState.paymentMethod, tagId: !!transactionAttributes?.tag?.id}}));
    setConfirmationVisible(true);
    setTimeout(() => setVisible(false), 0);
  }

  return (
    <div id="payout-invoice">
      {transactionAttributes && transactionAttributes.wallet && selectedPaymentMethod.enabled ? <>
          <InputGroup
            autocomplete="off"
            id="wallet"
            type="text"
            label={t("enterYour") + " " + selectedPaymentMethod.currency.name + " " +
                selectedPaymentMethod.name_view + " " + t("address")}
            name="wallet"
            placeholder={selectedPaymentMethod.currency.name + " " + selectedPaymentMethod.name_view + " " + t("address")}
            onChange={event => {
              updateAttribute(
                transactionAttributes.wallet,
                event.target.value
              );
            }}
          />
          {transactionAttributes.tag &&
            <TransactionAttributesTagBlock
              updateAttribute={updateAttribute}
              transactionAttributes={transactionAttributes}
              isMemo={isMemo}
              setIsMemo={setIsMemo}
              attributes={payoutSubmitData.attributes}
              t={t}
            />
          }
          <InputGroup
            autocomplete="off"
            id="amount"
            type="text"
            label={t("enterSum")}
            name="amount"
            placeholder={t("amount")}
            autoInput
            onChange={event => {
              setPayoutSubmitData((prevState) => ({
                ...prevState,
                amount: event.target.value
              }));
            }}
            value={valueNumber}
            betAction={
              <BetAction
                value={valueNumber}
                setValue={handleInput}
                min={Math.min(parseFloat(min), selectedPaymentMethod?.balances?.amount) === 0 ? "0.00000000" : Math.min(parseFloat(min), selectedPaymentMethod?.balances?.amount)}
                max={Math.min(parseFloat(max), selectedPaymentMethod?.balances?.amount)}
              />}
            error={validate(valueNumber)}
            hints={{
              ...selectedPaymentMethod,
              min: min,
              max: max,
            }}
            handleInput={handleInput}
          />
        </> :
        <InputGroup
          autocomplete="off"
          id="wallet"
          type="text"
          label={t("enterYour") + " " + selectedPaymentMethod.currency.name + " (" +
            selectedPaymentMethod.paymentSystem.name + ") " + t("address")}
          name="wallet"
          style={{ textAlign: "center" }}
          placeholder={t("Wallet Maintenance")}
          value={t("Wallet Maintenance")}
          onInput={e => e.target.value = t("Wallet Maintenance")}
        />
      }
      {!!paymentMethods ? (
          <>
            <StyledSelect mb={10} style={{marginTop: "17px", position: "relative"}} id={"select-payout"}>
              <StyledSelectLabel style={{ fontSize: 16 }}>{t("chooseNetwork")}:</StyledSelectLabel>
              <Select
                getPopupContainer={() => document.getElementById("select-payout")}
                className="custom-select"
                name="category"
                value={selectedPaymentMethod?.id}
                onChange={(value) => handleChangePaymentSystem(value)}
                virtual={false}
                dropdownAlign={{offset: [-1, 12]}}
              >
                {paymentMethods.map((value, key) => (
                  <Option
                    value={value.id}
                    key={key}
                  >
                    {`${value?.payoutFee?.constant && Number(value?.payoutFee?.constant) > 0 ? formatNumber(Number(value?.payoutFee?.constant)) : '0.00000000'} ${selectedPaymentMethod.currency.asset} - ${value?.name_view ? value?.name_view : "ERROR"}`}
                  </Option>
                ))}
              </Select>
            </StyledSelect>
          </>) :
        null
      }
      {transactionAttributes && transactionAttributes.wallet && selectedPaymentMethod.enabled ? <>
          <AlertMessage
            type="warning"
            message={t("makeSureYouEnteredCorrect") + " " +
              selectedPaymentMethod.currency.name + " " + t("address") + ". " +
              t("theTransactionFeeWillBe") + " " + selectedPaymentMethod.currency.name + "."}
            mt="30"
            mb="30"
          />
          <div className="confirm-action">
            <StyledButton
              onClick={openConfirmationModal}
              color="neutral"
              type="submit"
              width="100"
            >{t("continue")}</StyledButton>
          </div>
        </> :
        <>
          <Maintenance
            message={t("technicalMaintenance")}
            description={t("paymentMaintenance", {
              wallet: selectedPaymentMethod.currency.asset + (selectedPaymentMethod.name_view ? " (" + selectedPaymentMethod.name_view + ")" : "")
            })}
          />
          <StyledButton
            onClick={() => setVisible(false)}
            color="neutral"
            type="submit"
            width="100"
            mt={30}
          >
            {t("close")}
          </StyledButton>
        </>
      }
    </div>
  );
};

export default PayoutInvoice;
