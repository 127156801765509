import styled from "styled-components";
import animationIcon from "../../../assets/images/buttonIcons/animation-icon.svg";
import { hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledAnimationToggler = styled.button`
  .animation-icon {
    width: 11px !important;
      background: url(${animationIcon}) center ${({ animationDisabled }) => animationDisabled ? 'right' : 'left'} no-repeat;
      background-size: 22px 11px;
  }

  .text {
    ${hideOnMedia('xm')}
  }
`;
