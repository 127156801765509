import Dialog from "rc-dialog";
import {useTranslation} from "react-i18next";
import {StyledRadioPlayer} from "./styledRadioToggler";
import {useEffect} from "react";

const RadioTogglerModal = ({isVisible, setIsVisible, game, setIsPlay}) => {
    const {t} = useTranslation('siteOptions');

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'radioPlayerState') {
                setIsPlay(event.data.state === "play");
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <Dialog
            visible={isVisible}
            wrapClassName={`default-modal-wrapper ${game}`}
            onClose={() => setIsVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("radioTitle")}
            forceRender={false}
            className="default-modal radio"
        >
            <StyledRadioPlayer>
                <iframe src={"https://luckyfm.website/embed"}/>
            </StyledRadioPlayer>
        </Dialog>
    );
};

export default RadioTogglerModal;
