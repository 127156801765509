import styled from "styled-components";
import soundIcon from "../../../assets/images/buttonIcons/sound-icon.svg";
import { hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledSoundToggler = styled.button`
  .sound-icon {
    width: 11px !important;
    background: url(${soundIcon}) center ${({ sound }) => sound === "on" ? 'left' : 'right'} no-repeat;
    background-size: 22px 11px;
  }

  .text {
    ${hideOnMedia('xm')}
  }
`;