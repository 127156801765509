import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import AccountImageContainer from "../../account/client/AccountImageContainer";
import AccountSettingsWrapper from "../../account/client/AccountSettingsWrapper";
import ThemeToggler from "../themeToggler/ThemeToggler";
import SoundToggler from "../soundToggler/SoundToggler";
import AnimationToggler from "../animationToggler/AnimationToggler";
import jackpotImg from "../../../assets/images/icons/jackpot-icon.svg";
import tetherImg from "../../../assets/images/coins/svg/tether-icon.svg";
import { AppContext, MercureUrl } from "../../../App";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { jackpotConsts, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import { generatePath } from "../../../utils/getLanguage";
import { generateJWSToken } from "../../../utils/mercureAuth";
import Cookies from "js-cookie";
import SiteOptionsJackpot from "./SiteOptionsJackpot";
import http from "../../../http";

import { StyledSiteOptionsWrapper, StyledWelcomeContent } from "./styledSiteOptions";
import {StyledToggler} from "../themeToggler/styledToggler";
import {useBetween} from "use-between";
import BallsStates from "../../games/balls/BallsStates";
import HotkeysToggler from "../hotkeysToggler/HotkeysToggler";
import {formatNumber} from "../../../utils/formatNumber";
import RadioToggler from "../radioToggler/RadioToggler";
import Big from "big.js";

const SiteOptions = ({ animationDisabled, setAnimationDisabled, isRunMagnet, isMobile, paymentMethod }) => {
  const path = useLocation();
  let game = "dice";
  if (path.pathname.includes("balls")) {
    game = "balls";
  } else if (path.pathname.includes("roulette")) {
    game = "roulette";
  }
  const { t } = useTranslation("siteOptions");

  const [visibleJackpot, setVisibleJackpot] = useState(false);
  const { user, authenticated } = useContext(AppContext);
  const { submitData, setSubmitData } = useBetween(BallsStates);

  const [siteOptionsState, setSiteOptionsState] = useState({
    countOfAllBets: 0,
    sumOfWins: 0
  });
  const [jackpot, setJackpot] = useState(null);

  const fetchJackpot = () => {
    if (!paymentMethod || !game) {
      return;
    }

    http.get(`/api/game-jackpot?game=${game}&paymentMethod=${paymentMethod?.id}`,
      userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setJackpot({...response.data, value: formatNumber(new Big(response.data.value).toFixed(8))});
      }
    }).catch(error => {
      console.log(new Error().stack);
      closableNotification(error.response.data.message, "error");
    });
  };

  const updateJackpotValue = ({ jackpotGame, value }) => {
    if (jackpotGame.toLowerCase() !== game) {
      return;
    }

    setJackpot((prevState) => ({ ...prevState, value: formatNumber(value) }));
  }


  const topic = "updateSiteOptions";
  const token = generateJWSToken(topic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      if (!!dataMercure.jackpotGame) {
        updateJackpotValue(dataMercure);
        return;
      }

      setSiteOptionsState(prevState => ({
          countOfAllBets: prevState.countOfAllBets > dataMercure.countOfAllBets ? prevState.countOfAllBets : dataMercure.countOfAllBets,
          sumOfWins: prevState.sumOfWins > dataMercure.sumOfWins ? prevState.sumOfWins : dataMercure.sumOfWins,
      }));
    });

    return () => {
      es.close();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    http.get(
      '/api/get-count-of-all-bets-and-sum-of-wins',
      { ...userAuthenticationConfig(), signal: abortController.signal }
    ).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setSiteOptionsState(response.data);
      }
    }).catch(error => {
      console.log(new Error().stack);
      closableNotification(error.response.data.message, "error");
    });
  }, []);

  useEffect(() => {
    fetchJackpot();
  }, [paymentMethod]);

  function reverseNumbers() {
    let inputArray = JSON.parse(submitData.suggestedNumbers);
    if (!inputArray || inputArray.length < 1) return setSubmitData((prevState) => ({...prevState, suggestedNumbers: "[0,1,2,3,4,5,6,7,8]"}))

    let fullArray = Array.from({ length: 11 }, (_, i) => i);

    let resultArray = fullArray.filter(num => !inputArray.includes(num));

    resultArray.reverse();

    return setBallsNumbers(JSON.stringify(resultArray));
  }

  function setBallsNumbers(newBallsData) {
    setSubmitData((prevState) => ({...prevState, suggestedNumbers: newBallsData}))
  }

  return (
    <>
      {jackpot &&
        <SiteOptionsJackpot
          visibleJackpot={visibleJackpot}
          setVisibleJackpot={setVisibleJackpot}
          jackpot={jackpot}
          game={game}
          paymentMethod={paymentMethod}
        />
      }
      <StyledWelcomeContent isAuth={authenticated}>
        <div className="information__site user-info">
          {!authenticated && !user ?
            <>
              {t("welcome")}
              <span>Luckygames</span>
            </> :
            <>
              {t("greeting")}
              <AccountImageContainer/>
              <NavLink to={generatePath(`/account/${user.nickname}`)}>
                <span>{user.nickname}</span>
              </NavLink>
            </>
          }
        </div>
        <div className="main-info">
          <div className="information__site bet">
            <span>{new Intl.NumberFormat("en-US").format(siteOptionsState?.countOfAllBets ?? 0)}</span>
            {t("bet")}
          </div>
          <AccountSettingsWrapper/>
          <div className="information__site wins">
            {t("wins")}
            <span>{new Intl.NumberFormat("en-US").format(siteOptionsState?.sumOfWins.toFixed(0) ?? 0)}</span>
            <img
              src={tetherImg}
              alt="tether"
            />
          </div>
        </div>
      </StyledWelcomeContent>
      <StyledSiteOptionsWrapper
        isMobile={isMobile}
      >
        <div className="panel">
          <div className="panel__item">
            <ThemeToggler/>
          </div>
          <div className="panel__item">
            <SoundToggler/>
          </div>
          <div
              className="panel__item"
              onClick={() => !isRunMagnet && setAnimationDisabled(!animationDisabled)}
          >
            <AnimationToggler animationDisabled={animationDisabled}/>
          </div>
          {!isMobile && <div className="panel__item">
            <HotkeysToggler game={game} />
          </div>}
          <div className="panel__item">
            <RadioToggler game={game} />
          </div>
          {/*<div className="panel__item">*/}
          {/*  <PanelToggler />*/}
          {/*</div>*/}
          {
              isMobile && (
                  <div
                      className="jackpot"
                      onClick={() => setVisibleJackpot(true)}
                  >
                    <img
                        className="jackpot__img"
                        src={jackpotImg}
                        alt="jackpot"
                        style={{maxHeight: "12px"}}
                    />
                   {jackpot?.value ? jackpot?.value : ""} USDT
                  </div>
              )
          }
        </div>
        {
            !isMobile && (
                <div
                    className="jackpot"
              onClick={() => setVisibleJackpot(true)}
            >
              <img
                className="jackpot__img"
                src={jackpotImg}
                alt="jackpot"
                style={{maxHeight: "11px"}}
              />
                  {jackpot?.value ? jackpot?.value : ""} USDT
            </div>
          )
        }
        {game === "balls" && <div className={"roulette_panel"}>
          <div className="roulette_panel__item">
            <StyledToggler onClick={() => setBallsNumbers("[1,3,5,7,9]")}>
              ODD
            </StyledToggler>
          </div>
          <div className="roulette_panel__item">
            <StyledToggler onClick={() => setBallsNumbers("[0,2,4,6,8,10]")}>
              EVEN
            </StyledToggler>
          </div>
          <div className="roulette_panel__item">
            <StyledToggler onClick={() => reverseNumbers()}>
              REV
            </StyledToggler>
          </div>
          <div className="roulette_panel__item">
            <StyledToggler onClick={() => setBallsNumbers("[]")}>
              CLEAN
            </StyledToggler>
          </div>
        </div>}
      </StyledSiteOptionsWrapper>
    </>
  );
};

export default SiteOptions;
