import styled from "styled-components";
import hotkeysIcon from "../../../assets/images/buttonIcons/hotkeys-icon.svg";
import { hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledHotkeysToggler = styled.button`
    .hotkeys-icon {
        width: 11px !important;
        background: url(${hotkeysIcon}) center ${({hotkeysDisabled}) => hotkeysDisabled ? 'right' : 'left'} no-repeat;
        background-size: 22px 11px;
    }

    .text {
        ${hideOnMedia('xs')}
    }
    ${hideOnMedia('desktop')}    
`;

export const StyledHotkeysItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px;

    & .key {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32.5px;
        width: 90px;
        font-size: 16px;
        color: #ffffff;
        border-bottom: 1px #222222 solid;
        background: #222222;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        font-weight: 500;
    }

    & .description {
        width: 300px;
        float: left;
        font-size: 16px;
        line-height: 28px;

        &:before {
            content: "—";
            margin: 0 25px;
        }
    }

    @media screen and (max-width: 430px) {
        & .description {
            &:before {
                content: "";
                margin: 0 10px;
            }
        }
    }
`
