import { responseStatus } from "./consts";
import { closableNotification } from "../components/elements/notification/ClosableNotification";
import userAuthenticationConfig from "./userAuthenticationConfig";
import getUserInfo from "./getUserInfo";
import { randomHash } from "./random";
import http from "../http";

const fetchHonestyControl = (setCurrentHonestyControl, game) => {

  let user = getUserInfo();

  if (!user) {
    return;
  }

  http.post("/api/honesty-controls", { game: game }, userAuthenticationConfig(false)).then(response => {
    if (response.status === responseStatus.HTTP_CREATED) {
      setCurrentHonestyControl(prevState => ({
        ...(prevState || {}),
        clientSeed: randomHash(16),
        [response.data.game.toLowerCase()]: {
          serverSeedHash: response.data.serverSeedHash,
        },
        game: response.data.game
      }));
    }
  }).catch(error => {
    if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
      console.log(new Error().stack);
      closableNotification(error.response.data.error, "error");
    }
  });
};

export { fetchHonestyControl };
