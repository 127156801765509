import { css, DefaultTheme, ThemedCssFunction } from "styled-components";

const screen = {
  xxs: 320,
  xs: 480,
  xm: 600,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1440,
  xxl: 1920,
  desktop: 1100
}

export const device = (
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'desktop' | number,
  type: 'min' | 'max' = 'max'
) => {
  const width = (typeof size === 'number' ? size : screen[size]) + (type === 'min');
  return `(${type}-width: ${width}px)`
};

export const media = (
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'desktop' | number,
  type: 'min' | 'max' = 'max'
) => ({
  css: (...args): ThemedCssFunction<DefaultTheme> => css`
    @media screen and ${device(size, type)} {
      ${css(...args)}
    }
  `
})

export const hideOnMedia = (
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'desktop' | number,
  type: 'min' | 'max' = 'max'
) => (media(size, type).css`display: none !important;`);