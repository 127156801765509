import styled from "styled-components";
import logo from "../../../assets/images/logo.app.svg"
import mobLogo from "../../../assets/images/mobile-logo.svg"
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledLogoWrapper = styled.div`
  display: inline-block;
  width: 220px;
  height: 70px;
  background: url(${logo}) no-repeat center;
  background-size: contain;
  transition: none;
  ${({theme}) => theme.logo};
  &:hover {
    ${({theme}) => theme.logoHover};
  }
  
  @media screen and ${device('desktop')} {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    background: url(${mobLogo}) no-repeat center;
    background-size: 43px 48px;
    opacity: 1;
  }

  @media screen and (max-width: 360px) {
    width: 40px;
    height: 40px;
  }
`;