import React, { useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { useBetween } from "use-between";
import { OurStatistics } from "./OurStatistics";
import { generateJWSToken } from "../../../../utils/mercureAuth";
import { MercureUrl } from "../../../../App";
import Cookies from "js-cookie";
import { defaultOurStatistics } from "../../../../utils/consts";
import { useAnimationSyncData } from '../../../games/AnimationSyncDataStates';
import StatisticsBlock from './elements/StatisticsBlock';
import StatisticsResults from './elements/StatisticsResults';
import StatisticsCounter from './elements/StatisticsCounter';


const LeftSidebarAccountStatisticsContainer = React.memo(({
  setNotification,
  notification,
  activeCurrency,
  selectedPaymentMethod,
  user,
}) => {
  const isMounted = useRef(false);
  const { ourStatistics, setOurStatistics } = useBetween(OurStatistics);
  const { t } = useTranslation("leftSidebar");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    const topic = "updateStatistic";
    const token = generateJWSToken(topic);
    MercureUrl.searchParams.delete("topic");
    MercureUrl.searchParams.append("topic", topic);
    Cookies.set("mercureAuthorization", token, { path: "" });
    const es = new EventSource(MercureUrl, { withCredentials: true });
    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      let currentCurrency = selectedPaymentMethod;
      if (user && dataMercure.userId && user.userId === dataMercure.userId &&
        dataMercure.currency === currentCurrency?.currency.asset) {
        setOurStatistics(dataMercure);
      }
    });
    return () => {
      es.close();
    };
  }, [setOurStatistics, user, selectedPaymentMethod]);

  const fetchUserStatistics = useCallback((abortSignal) => {
    let paymentMethod = selectedPaymentMethod;
    if (user && paymentMethod) {
      const url = `/api/user-statistics-by-currencies/${user?.nickname}?asset=${paymentMethod?.currency.asset}`;
      axios.get(url, { ...userAuthenticationConfig(false), signal: abortSignal }).then(response => {
        if (response.status === 200) {
          const data = response.data || [];
          const stat = data[0];

          if (!isMounted.current) {
            return;
          }
          if (stat) {
            setOurStatistics(stat);
          } else {
            setOurStatistics(defaultOurStatistics);
          }
        }
      }).catch(error => {
        console.log('fetchUserStatistics,error:', error);
        if (error?.message === 'canceled') {
          return;
        }
        if (!isMounted.current) {
          return;
        }
        setNotification((prevValue) => ({
          ...prevValue,
          visible: true,
          type: 'error',
          message: error.response.data.message,
        }));
      });
    } else {
      if (!isMounted.current) {
        return;
      }
      setOurStatistics(defaultOurStatistics);
    }
  }, [user, selectedPaymentMethod, setNotification, setOurStatistics]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchUserStatistics(signal);

    return () => {
      controller.abort();
    }
  }, [activeCurrency, fetchUserStatistics]);

  const {
    sumOfBet,
    profit,
    countOfBet,
    countOfWins,
    countOfLose,
    percentOfLuck,
    countOfMessages,
  } = useAnimationSyncData(ourStatistics);

  return (
    <>
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("generalBet")}
        result={Number(sumOfBet).toFixed(8)}
      />
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("totalProfit")}
        result={Number(profit).toFixed(8)}
      />
      <StatisticsResults
        countOfBet={countOfBet}
        countOfWins={countOfWins}
        countOfLose={countOfLose}
      />
      <StatisticsCounter
        percentOfLuck={percentOfLuck}
        countOfMessages={countOfMessages}
      />
    </>
  );
});

export default LeftSidebarAccountStatisticsContainer;
